<template>
	<van-tabbar v-if="show" v-model="active" active-color="#ff0000" :border="true" inactive-color="#979799">
		<van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
			<span :class="key === 2 ? '' : ''">{{ k.title }}</span>
			<template #icon="props">
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key !== 2" :class="props.active ? 'active' : ''"/>
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" v-show="key === 2" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			item: [
				{
					router: '/Home',
					title: this.$t('home'),
					icon: {
						active: '/img/footer/ic1a.png',
						noactive: '/img/footer/ic1.png'
					}
				},
				{
					router: '/GameRecord',
					title: this.$t('history'),
					icon: {
						active: '/img/footer/ic2a.png',
						noactive: '/img/footer/ic2.png'
					}
				},
				{
					router: '/Game',
					title: '',
					icon: {
						active: '/img/footer/ic-menu.png',
						noactive: '/img/footer/ic-menu.png'
					}
				},
				{
					router: '/ServiceOnline',
					title: this.$t('support'),
					icon: {
						active: '/img/footer/support.png',
						noactive: '/img/footer/support.png'
					}
				},
				{
					router: '/Mine',
					title: this.$t('mine'),
					icon: {
						active: '/img/footer/ic4a.png',
						noactive: '/img/footer/ic4.png'
					}
				}
			]
		};
	},
	methods: {},
	watch: {
		$route(to) {
			if (to.name == 'home') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'game') {
				this.active = 1;
				this.show = true;
			} else if (to.name == 'choose') {
				this.active = 2;
				this.show = true;
			} else if (to.name == 'video') {
				this.active = 3;
				this.show = true;
			} else if (to.name == 'mine') {
				this.active = 4;
				this.show = true;
			} else {
				this.show = false;
			}
		}
	},
	created() {
		if (this.$route.name == 'home') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'game') {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == 'choose') {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == 'video') {
			this.active = 3;
			this.show = true;
		} else if (this.$route.name == 'mine') {
			this.active = 4;
			this.show = true;
		} else {
			this.show = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 75px;
@tabbar-img: 25px;
.van-tabbar {
	height: @tabbar-height;
	max-width: 600px;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0 1px 50px 1px #e4e4e7;
}
.van-tabbar-item__icon img {
	height: @tabbar-img;
}
.van-tabbar-item__icon img.active:not(.tui) {
	filter: hue-rotate(305deg);
}
.van-tabbar-item {
	font-size: 14px;
}
::v-deep .van-tabbar-item__text {
	white-space: nowrap;
}
::v-deep .van-tabbar-item__text .menu {
	top: -300%;
	position: relative;
	color: #000;
	font-size: 10px;
	font-weight: bold;
}
.tui {
	width: 85px;
	height: 85px!important;
	margin-top: -38.333px;
	background-color: white;
	border-radius: 50%;
	border: 5px solid white;
	z-index: 10;
}
[class*='van-hairline']::after {
	border: none !important;
}
@media (max-width:500px){
	.van-tabbar-item__icon img {
		height:20px;
		width: auto;
	}
	.van-tabbar-item {
		font-size: 10px;
	}
	.van-tabbar {
		height: 55px;
	}
	.tui {
		height: 60px!important;
		width: 60px!important;
		margin-top: -20px
	}
}
</style>
