<template>
	<div class="container page">
		<van-nav-bar :title="$t('sex')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="sex">
			<van-radio-group v-model="radio">
				<div class="item van-hairline--bottom" @click="chooesSex(1)">
					<van-radio name="1">{{ $t("male") }}</van-radio>
				</div>
				<div class="item van-hairline--bottom" @click="chooesSex(2)">
					<van-radio name="2">{{ $t("female") }}</van-radio>
				</div>
			</van-radio-group>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			radio: "",
			userInfo: {},
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		chooesSex(sex) {
			this.$http({
				method: "post",
				data: {sex: sex},
				url: "user_set_sex",
			}).then(res => {
				if (res.code === 200) {
					this.getUserInfo();
					this.radio = sex;
					this.$toast(res.msg);
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.radio = res.data.sex;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getUserInfo();
		}
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.container .sex {
	background-color: #fff;
	padding: 0 20px;
}
.container .sex .item {
	font-size: 16px;
	line-height: 40px;
	padding: 15px 0;
}
::v-deep .van-radio__label {
	line-height: 30px;
	margin-left: 10px;
}
::v-deep .van-radio__icon {
	font-size: 16px;
}
::v-deep .van-radio__icon--checked .van-icon {
	color: #fff;

	background: linear-gradient(to right, #fe8f30, rgb(255, 51, 132));
}
.container .van-hairline--bottom::after {
	border-bottom-width: 3px;
}
</style>
