<template>
	<div class="page">
		<van-nav-bar :title="$t('heaven')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
			<p class="title">{{ this.xuanfeidata.vo_title }}</p>
			<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">{{$t('save')}}</van-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: []
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			this.$toast(this.$t('pls_contact'));
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25px;
}
.name {
	font-size: 1.125px;
}
.title {
	font-size: 0.625px;
}
.button {
	width: 10px;
	height: 2.5px;
	font-size: 0.9375px;
	margin-top: 0.625px;
}
</style>
