<template>
<div class="convention-hall page">
  <div class="convention-items">
    <div>
      <van-sidebar @change="onChange" v-model="activeKey" class="convention-item">
        <van-sidebar-item :title="$t('all')" />
        <van-sidebar-item v-for="(v,key) in lotteryitem" :key="key" :title="v.name" />
      </van-sidebar>
    </div>
    <div class="hot-items-div">
      <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" >
        <van-grid :column-num="2">
          <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key" class="game_item">
            <van-image class="game_item_img" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
            <p>{{v.name}}</p>
          </van-grid-item>
        </van-grid>
      </van-pull-refresh>
    </div>
  </div>
</div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      gameitem: [{},{},{},{}],
      lotteryitem: [{},{},{},{}],
      isLoading: false,
      activeKey: 0,
    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        Toast(this.t('renew'));
        this.isLoading = false;
      }, 500);
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data:{class:index},
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
      })
    },
    getLotteryItem(){
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res=>{
        this.lotteryitem = res.data;
      })
    }
  },
  created() {
    this.getGameItem();//Lấy list game ra trang chủ
    this.getLotteryItem();
  }
};
</script>

<style lang='less' scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar{
  background: linear-gradient(to right, #fe8f30, rgb(255, 51, 132));
  height: 40px;

}
.van-nav-bar {
  line-height: 40px;
}

::v-deep .van-nav-bar__title {
  max-width: 80%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 18px;
}
::v-deep .van-nav-bar__content {
  height: 40px;
}
.van-sidebar {
  width: 100%;
}
.van-sidebar-item--select::before {
  display: none;
}

.van-sidebar-item--select {
  background: linear-gradient(to bottom right,#fae64f,#f79883); 
  font-size: 13px;
  text-align: center;
}
.van-sidebar-item{
  font-size: 13px;
  text-align: center;
  padding: 5px;
  background: linear-gradient(to bottom right,#8da5ce,#46c0f9);
  width: 100%;
  color: #fff;
  margin: 1px;
  height: 43.6px;
  line-height: 35px;
}
.van-sidebar-item--select, .van-sidebar-item--select:active {
  background: linear-gradient(to bottom right,#fae64f,#f79883);;
}
.convention-item{
  display: flex;
  align-items: center;
  padding: 2px;
  width: calc(100% - 4px);
}
.convention-hall{
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #bac3d2;
}
.convention-item .left{
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 27%;
}
.convention-item .right{
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-height: calc(100vh - 50px);
}
.convention-item .right .list-wrapper{
  padding: 5px;
}
.convention-item .right .list-wrapper .game_item_img{
  width: 85px;
  height: 85px;
}
.convention-item .right .list-wrapper span{
  margin-top: 10px;
  font-size: 15.5px;
  color: #000;
}
.convention-item .right .list-wrapper span:last-child{
  margin-top: 10px;
  font-size: 13.5px;
  color: #000;
}
.van-grid-item {
  padding: 5px;
}
::v-deep .van-grid-item__content--center {
  border-radius: 10px;
}
::v-deep .van-image__img{
  border-radius: 20px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 16px;
}
::v-deep.game_item {
  padding: 5px;
  max-width: 50%;;
}
::v-deep.game_item p {
  margin: 8px 0 5px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background: #ef3340;
  width: 140px;
  padding: 5px;
  text-align: center;
}
::v-deep.game_item span {
  font-size: 12px;
  color: #4e4e4e;
}
::v-deep.game_item > div{
    padding: 10px 10px 5px;
    background: #ebedec;
    border-radius: 5px;
    box-shadow: 0 2px 3px -1px rgba(255,87,34,.5);
    overflow: hidden;
}
.hot-items-div .game_item_img{
  width: 180px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 3px;
}
</style>
