<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="Địa chỉ" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="content">
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      userInfo:{
      },
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast(this.t('offline'));
              localStorage.removeItem('token')
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = this.$t('login_regis');
      this.userInfo.ip = this.$t('text_login_regis');
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
</style>
